@import './variables';

$theme-colors: (
  primary: $primary-color,
  /* facebook: #4568b2,*/ /* google: #fff,*/
);

@import '~bootstrap/scss/bootstrap';

// Help keeping footer stays at the bottom even when the page content is short
html,
body,
#root,
#root > div {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  // background-color: $main-background-color;
  // color: $font-color-base;
}

.Header {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.TableOptions {
  display: flex;
  justify-content: space-between;
}

.TableColumnHeaderSortable {
  cursor: pointer;
}

.TableClickableRow {
  cursor: pointer;
}

.TableLoadingOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1000;
}

.Pagination {
  justify-content: flex-end;
}

.SecondaryIcon {
  fill: $secondary;
}

.SuccessIcon {
  fill: $success;
}

.DangerIcon {
  fill: $danger;
}

.WarnIcon {
  fill: $warning;
}

.FillWhite {
  fill: $white;
}

.FillDark {
  fill: $dark;
}

.card-col {
  margin-bottom: 1rem;
}

.Modal-MinimalPadding {
  .modal-header {
    padding: 0.5rem 1rem;
  }

  .modal-footer {
    padding: 0.5rem;
  }
}

.Chart svg {
  overflow: visible;
}

.PreviousLink {
  float: left;
  margin: 0.5rem 0 0.5rem 0;
}

.NextLink {
  float: right;
  margin: 0.5rem 0 0.5rem 0;
}
